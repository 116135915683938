<template>
    <a-list class="cert-list"  
            item-layout="horizontal" 
            :loading="fetchingCerts" 
            :data-source="certs"
            :pagination="paginationConfig">
      <a-list-item slot="renderItem" slot-scope="certs">
        <a-card :body-style="{ padding: 0 }" class="w-100">
          <CertListItem :certs="certs" />
        </a-card>
      </a-list-item>
    </a-list>
  </template>
  
  <script>
  import CertListItem from '@/views/cert/CertListItem.vue';
  
  export default {
    components: {CertListItem},
    props: ['fetchingCerts','certs','currentPage','certPerPage','totalCertsToPaginate'],
    computed: {
      
      paginationConfig() {
        const me = this;
        return {
          current: this.currentPage,
          pageSize: this.certPerPage,
          total: this.totalCertsToPaginate,
          onChange: function(page) {
            me.$emit('pageChanged', page);
          },
        }
      }
  },
    data() {
      return {};
    },
  };
  </script>

<style>
.cert-list .ant-list-pagination {
  text-align: center;
}
</style>