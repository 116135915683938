<template>
  <div class="w-100">
    <!-- mobile -->
    <div class="d-xs-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
      <div class="mb-4 d-flex justify-content-end">
        <slot name="action-buttons"></slot>
      </div>
      <slot name="filters"></slot>
    </div>
    
    <!-- desktop -->
    <div class="d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center">
      <div class="w-50 d-flex">
        <slot name="filters"></slot>
      </div>
      <slot name="action-buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>