<template>
    <div>
        <a-modal :visible="show" :closable="false" title="Specify date range">
            <p>{{ $t('start_date') + ' ~ ' + $t('end_date') }}</p>
            <a-range-picker :value="timeRangePickerValue" @change="handleRangeChange" :disabled-date="disableDateRestriction ? disabledDate : null" />
            <template slot="footer">
                <a-button key="back" @click="hideCustomRangePicker">
                    {{ $t('Close') }}
                </a-button>
                <a-button key="submit" type="primary" @click="handleConfirmRange">
                    {{ $t('Okay') }}
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
        disableDateRestriction: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            timeRangePickerValue: [moment(), moment()],
        }
    },
    methods: {
        handleRangeChange(newVal) {
            this.timeRangePickerValue = newVal;
        },
        disabledDate(current) {
            const twoMonthsAgo = moment().subtract(2, 'months');
            const isBeforeTwoMonthsAgo = current.isBefore(twoMonthsAgo);
            const isAfterToday = current.isAfter(moment());

            return current && (isBeforeTwoMonthsAgo || isAfterToday);
        },
        hideCustomRangePicker() {
            this.$emit('close');
        },
        handleConfirmRange() {
            this.$emit('change', [moment(this.timeRangePickerValue[0]), moment(this.timeRangePickerValue[1])]);
        },
    }
}
</script>
