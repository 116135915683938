<template>
    <div :class="{'domain-list-item': true, 'd-flex': true, 'flex-column': true, 'selected': isSelected}">
      <div class="actionable-buttons px-5 py-2 d-flex align-items-center">
        <div>
          <input type="checkbox" v-model="isSelected" @change="handleSelectBoxChange" class="select-box">
        </div>
        <div class="d-flex flex-fill justify-content-between">
            <div class="plugin-indicators d-flex">
              <div>
                <CButton type="button" :class="{'btn': true, 'btn-sm': true}"
                     @click="downloadCert(certs.id, certs.domain)">
                    <a-icon type="download"/>
                </CButton>
              </div>
            </div>
        </div>
      </div>
  
      <div class="px-5 py-4 d-flex">
        <div class="w-100 d-flex flex-column justify-content-center">
          <div class="d-flex align-items-center">
            <h3 class="mb-0 mr-1">{{ certs.domain }}</h3>
            <!-- <a-tag>
              {{ certs.type }}
            </a-tag> -->
          </div>
  
          <section class="shadow-domains">
            <ul class="p-0">
              <li class="mr-2" v-if="certs.snis.length >1">{{ certs.snis[1] }}</li>
              <li class="mr-2" >{{ certs.snis[0] }}</li>
            </ul>
          </section>
  
          <section class="notes mt-4">
            <p class="notes w-75" v-if="certs.issuer_name.length">
              <span>SSL Issuer : {{ certs.issuer_name }}</span>
            </p>
            <p class="notes w-75" v-if="!certs.issuer_name.length">
              <span>SSL Issuer : - </span>
            </p>
            <p class="notes w-75" v-if="certs.type == 'HTTP' && certs.is_force_https == true">
              <span>SSL Validation Method : HTTPS </span>
            </p>
            <p class="notes w-75" v-else-if="certs.type == 'HTTP' && !certs.is_force_https == true">
              <span>SSL Validation Method : HTTP </span>
            </p>
            <p class="notes w-75" v-else-if="certs.type == 'DNS'">
              <span>SSL Validation Method : DNS </span>
            </p>
            <p class="notes w-75">
                <span>Issue Date : {{ $moment(certs.issue_at).format('MMM D, YYYY') }}</span>
            </p>
            <p class="notes w-75">
                <span>Expires Date : {{ $moment(certs.expired_at).format('MMM D, YYYY') }}</span>
            </p>
          </section>
        </div>
  
        <div class="w-25 d-flex justify-content-end">
          <section class="domain-action d-flex flex-column align-items-end">
            <header>
              <span>{{ $t('Status') }}</span>
            </header>
            <div>
              <ul class="domain-action-target mt-1 p-0">
                <li>{{ certs.status }}</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </template>
  <script>
  import {EventBus, Events} from '@/plugins/event-bus';
  
  
  
  export default {
    props: ['fetchingCerts','certs'],
    data() {
      return {
        isSelected: false,
      }
    },
    mounted() {
      EventBus.$on(Events.SELECT_ALL_DOMAIN, this.selectAllDomain);

      EventBus.$on(Events.DESELECT_ALL_DOMAIN, this.deselectAllDomain);
  },
    beforeDestroy() {
      EventBus.$off(Events.SELECT_ALL_DOMAIN, this.selectAllDomain);

      EventBus.$off(Events.DESELECT_ALL_DOMAIN, this.deselectAllDomain);
  },
    methods: {
    selectAllDomain() {
      this.doSelectBoxChange(true);
    },
    deselectAllDomain() {
      this.isSelected = false;
    },
    handleSelectBoxChange(e) {
      this.doSelectBoxChange(e.target.checked);
    },
    doSelectBoxChange(checked) {
      this.isSelected = checked;
      EventBus.$emit(checked ? Events.SELECT_DOMAIN : Events.DESELECT_DOMAIN, {certs: this.certs});
    },
    downloadCert(id,name) {
        const data = {
                    'id':id,
                    'name':name
                }

        EventBus.$emit(Events.DOWNLOAD_CERTIFICATE, (data))
        },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .domain-list-item.selected {
    border: 2px solid orange;
  }
  
  h3 {
    color: #0EA5E9;
    font-size: 1.35em;
  }
  
  .plugin-indicators {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  
  .domain-cname-button {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  
  section.shadow-domains {
    ul {
      li {
        display: inline-block;
      }
    }
  }
  
  section.notes {
    header {
      color: #94A3B8;
      font-size: 0.8em;
  
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  
  section.domain-action {
    header {
      color: #94A3B8;
      font-size: 0.8em;
  
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  
  .domain-action-target {
    list-style-type: none;
    color: #0EA5E9;
  
    li {
      display: inline-block;
    }
  }
  
  .actionable-buttons {
    background-color: #f9fafb;
    border-bottom: 2px solid #E2E8F0;
  }
  
  .select-box {
    margin-left: -1.75rem;
    margin-right: 0.5rem;
  }
  </style>