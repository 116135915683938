<template>
  <div>
      <CButton color="success"
      size="sm"
      class="mb-2 mr-2"
      @click="clickZipFile">
        <CSpinner v-if="isProcessingFile" size="sm"/>
          {{ $t('cert.UploadZIPFile') }}
      </CButton>
        <input type="file" style="display: none" id="zipfile" accept="application/zip, application/octet-stream" @change="uploadZipCertificate"/> 
        <p style="color: #a0a0a0">{{ $t('cert.InstructionZIPFile') }} <a href="/example_format.zip" class="mb-2">{{ $t('cert.ExampleFile') }}</a>.</p>
      <div>
        <div class="d-flex justify-between">
          <ListActionBar>
            <template v-slot:filters>
              <div class="d-flex w-100 align-items-center">
                <div class="searchInput">
                  <a-input-search
                      :placeholder="$t('message.KeywordSearch')"
                      @search="doSearch">
                    <a-button slot="enterButton" icon="search"/>
                  </a-input-search>
                </div>
                <div class="mx-2">
                  <a-button class="ant-btn" @click="visibleModalFindDomain = !visibleModalFindDomain">
                    {{ $t('FindCertificates') }}
                  </a-button>
                  <a-modal :visible="visibleModalFindDomain" :title="$t('FindCertificates')"> 
                    <a-textarea rows="25" v-model="findMultiDomain" :placeholder="$t('PlaceholderFindCertificates')" allow-clear />
                    <template #footer>
                      <a-button key="close" @click="visibleModalFindDomain = !visibleModalFindDomain">{{ $t('Close') }}</a-button>
                      <a-button key="clear" @click="findMultiDomain = ''">{{ $t('Clear') }}</a-button>
                      <a-button key="submit" type="primary" @click="visibleModalFindDomain = !visibleModalFindDomain; fetchData()">{{ $t('Find') }}</a-button>
                    </template>
                  </a-modal>
                </div>
                <div class="d-flex align-items-center" v-if="!isSearchProgress">
                  <a-button class="ant-btn primary-outline">
                  {{ $t('total_domains') }}: <span> {{ total_rows }} </span>
                  </a-button>
                </div>
              </div>
            </template>
            <template v-slot:action-buttons>
              <div class="mx-2">
                <a-button-group v-if="isBatchEditMode" class="batch-edit-button-group">
                  <a-button @click="endSelect">
                    {{ $t('Cancel') }}
                  </a-button>
                  <a-button @click="selectAll">
                    {{ $t('SelectAll') }}
                  </a-button>
                  <a-button @click="downloadAll">
                    <a-icon type="download"/>
                    {{ $t('Download All') }}
                  </a-button>
                </a-button-group>
              </div>
            </template>
          </ListActionBar>
          <div>
            <a-popover v-model="showPopoverListFilter" :title="$t('Filter')" trigger="click" placement="bottomRight">
              <div slot="content">
                <table>
                  <tbody>
                  <tr>
                    <td class="py-2">{{ $t('CertificatesPerPage') }}</td>
                    <td class="pl-2 py-2">
                      <a-select size="small" :default-value="perpage" style="min-width: 120px"
                                @change="pageSizeChanged" class="w-100">
                        <a-select-option :value="100">
                          100
                        </a-select-option>
                        <a-select-option :value="250">
                          250
                        </a-select-option>
                        <a-select-option :value="1000">
                          1000
                        </a-select-option>
                      </a-select>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-2">{{ $t('cert.table.ExpiresDate') }}</td>
                    <td class="py-2">
                      <a-button @click="showCustomRangePicker = true" class="mr-2 mt-1 ml-2 ant-btn-sm w-100" style="min-width: 120px">{{ timeRangeDisplay ?? $t('Custom') }}</a-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <a-button class="ant-btn popover-list-filter-trigger btn d-flex align-items-center" type="primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                <span class="mx-2">{{ $t('Filter') }}</span>
              </a-button>
            </a-popover>
          </div>
        </div>
        <div class="mt-2">
          <p class="mb-0" v-if="selectedRows.length">{{ $t('SelectedDomains') }}
            {{ selectedRows.length }}</p>
        </div>
          <CertList :fetching-certs="listLoading" 
                      :certs="items"
                      :current-page="page"
                      :cert-per-page="perpage" 
                      :total-certs-to-paginate="total_items"
                      @pageChanged="pageChanged" />
        
      </div>
      <DateRangePicker :show="showCustomRangePicker" @change="handleDateRangePickerChange"
      @close="hideCustomRangePicker" :disableDateRestriction="false"/>
  </div>

</template>

<script>
import {format, formatISO} from 'date-fns';
import moment from 'moment';
import axios from '@/plugins/axios'
import { saveAs } from 'file-saver'
import ListActionBar from '@/views/domain/ListActionBar';
import {EventBus, Events} from "@/plugins/event-bus.js";
import CertList from '@/views/cert/CertList.vue';
import {getCerts} from '../../utilities/api';
import {ExportDomainMixin} from '@/mixins/ExportDomainMixin';
import DateRangePicker from '@/views/domain/charts/DateRangePicker.vue';

export default {
  name: 'Cert',
  mixins: [ExportDomainMixin],
  components: {
    ListActionBar,
    CertList,
    DateRangePicker,
  },
  data () {
    return {
      timeRange: [],
      showCustomRangePicker: false,
      selectedSearchCerts: [],
      items: [],
      listLoading: false,
      total_rows: 0,
      total_pages: 10,
      perpage: 100,
      page: 1,
      total_items: 0,
      isProcessingFile: false,
      enableSelect: false,
      selectedRows: [],
      showPopoverListFilter: false,
      certQ: null,
      isSearchProgress: false,
    }
  },
  props: {
    caption: {
      type: String,
      default: 'cert.CertList'
    }
  },
  computed: {
    isBatchEditMode: function () {
      return this.selectedRows.length > 0;
    },
    fields() {
      return [
        {
          key: 'type',
          label: this.$t('cert.table.Type')
        },
        {
          key: 'domain',
          label: this.$t('cert.table.Domain')
        },
        {
          key: 'snis',
          label: this.$t('cert.table.SNIS')
        },
        {
          key: 'status',
          label: this.$t('cert.table.Status')
        },
        {
          key: 'issue_at',
          label: this.$t('cert.table.IssueDate')
        },
        {
          key: 'expired_at',
          label: this.$t('cert.table.ExpiresDate')
        },
        {
          key: 'action',
          label: ''
        }
      ]
    },
    timeRangeDisplay: function () {
      if (!this.timeRange[0] || isNaN(new Date(this.timeRange[0]).getTime())) return null;
      return format(new Date(this.timeRange[0]), 'yyyy-MM-dd') + ' ~ ' + format(new Date(this.timeRange[1]), 'yyyy-MM-dd');
    },
  },
  watch: {
    '$parent.isActive'() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData();
    this.listenEvent();
  },
  beforeDestroy() {
    this.closeWs()
  },
  methods: {
    moment,
    clickZipFile() {
      document.getElementById('zipfile').click();
    },
    pageSizeChanged(pageSize) {
      this.page = 1;
      this.perpage = pageSize;
      this.selectedRows = [];
      this.fetchData();
      EventBus.$emit(Events.DESELECT_ALL_DOMAIN);
    },
    doSearch(value) {
      this.isSearchProgress = true;
      this.certQ = value;
      this.fetchData();
      EventBus.$emit(Events.DESELECT_ALL_DOMAIN);
      this.selectedRows = [];
    },
    selectAll() {
      EventBus.$emit(Events.SELECT_ALL_DOMAIN);
    },
    uploadZipCertificate(event) {
      if (event.target.files.length === 0) {
        return;
      }
      this.isProcessingFile = true;
      const zipfile = event.target.files[0];
      const formData = new FormData();
      formData.append('zipfile', zipfile);
      axios.post('cert/zip-upload', formData).then(response => {
        this.$message.success(response.data); 
        this.fetchData();
      }).catch(error => {
        this.$message.error(error.error.response.data);
      }).finally(() => {
        this.isProcessingFile = false;
        document.getElementById("zipfile").value = "";
      });
    },
    pageChanged(page) {
      this.page = page;
      this.endSelect();
      this.fetchData();
    },
    selectRow(item) {
      if (!this.selectedRows.includes(item)) {
        this.selectedRows.push(item);
      }
    },
    startSelect() {
      this.enableSelect = true
    },
    endSelect() {
      this.enableSelect = false;
      this.selectedRows = [];

      EventBus.$emit(Events.DESELECT_ALL_DOMAIN);
    },
    deselectRow(item) {
      if (this.selectedRows.includes(item)) {
        this.selectedRows.splice(this.selectedRows.indexOf(item), 1);
      }
    },
    downloadCert(id, name) {
      axios({
        url: `domain/ssl/zip/`,
        method: 'POST',
        responseType: 'blob',
        data:{
          id: `${id}`
        }
      }).then(response => {
        saveAs(response.data, `${name}.zip`)
      }).catch(error => {
        console.log(error)
      })
    },
    fetchData() {
      this.listLoading = true
      let searchCerts = [];
      for (let d of this.selectedSearchCerts) {
        searchCerts.push(d.name);
      }
      if (this.findMultiDomain) {
        let findMultipleDomain = this.findMultiDomain.split('\n');
        searchCerts = searchCerts.concat(findMultipleDomain.map(element => element.trim()));
        searchCerts = [...new Set(searchCerts)];
        EventBus.$emit(Events.DESELECT_ALL_DOMAIN);
        this.selectedRows = [];
    }

      const certParams = {
          page: this.page,
          perpage: this.perpage,
          q: this.certQ,
          q1: searchCerts.join(","),
      };

      if (this.timeRange[0]) {
        certParams.startDate = this.timeRange[0];
      }

      if (this.timeRange[1]) {
        certParams.endDate = this.timeRange[1];
      }

      return getCerts(certParams)
      .then(response => {
        this.items = response.data
        this.total_rows = this.items.length;
        this.total_pages = Math.ceil(this.total_rows / this.perpage) || 1;
        this.total_items = this.total_pages * this.perpage;
        this.listLoading = false
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.isSearchProgress = false;
        this.listLoading = false
      })
    },
    downloadAll() {
      const body_data = this.selectedRows
      const data = []
      body_data.forEach((item) => {
        if ( item != undefined ){
          data.push({
            'id': item.id,
            'snis': item.snis[0]
          })
        }
      });
      axios({
        url: `domain/ssl/zip/`,
        method: 'POST',
        responseType: 'blob',
        data:{
          id: data
        }
      }).then(response => {
        saveAs(response.data, `certificates.zip`)
      }).catch(error => {
        console.log(error)
      })
    },
    listenEvent() {
      // Send the event on a channel (i-got-clicked) with a payload (the click count.)
    
      EventBus.$on(Events.DOWNLOAD_CERTIFICATE, (data) => {
        this.downloadCert(data['id'],data['name']);
      });
      EventBus.$on(Events.SELECT_DOMAIN, (data) => {
        this.selectRow(data.certs);
      });

      EventBus.$on(Events.DESELECT_DOMAIN, (data) => {
        this.deselectRow(data.certs);
      });
    },
    hideCustomRangePicker() {
      this.showCustomRangePicker = false;
    },
    handleDateRangePickerChange(newRange) {
      const start = moment(newRange[0]).format('YYYY-MM-DD');
      const end = moment(newRange[1]).format('YYYY-MM-DD');
      this.timeRange = [start, end];
      this.hideCustomRangePicker();
      this.fetchData();
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.searchInput .form-control {
  height: 43px !important;
}

.multiselect-search {
  top: 5px;
}

.filter-controls .multiselect__content .load-more {
  font-size: 0.9em;
  margin-left: 12px;
  color: #39f;
  cursor: pointer;
}

.filter-controls .multiselect__content .load-more:hover {
  text-decoration: underline;
}

.GrayLock {
  color: gray;
}

.GreenLock {
  color: green;
}

.source-config-value {
  display: flex;
  align-items: center;
}

.source-config-value span {
  padding-left: 6px;
}

.domain-notes {
  white-space: nowrap;
  width: 100%; /* IE6 needs any width */
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}

#filterBox {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  display: none;
}

#filterBox.Show {
  display: flex !important;
}
</style>

<style lang="scss">
.multiselect .multiselect__tags {
  padding: 5px 40px 0 8px;
  border: 1px solid #d9d9d9;
}

.searchInput .ant-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 0.9em;
}
.searchInput .ant-input::-moz-placeholder { /* Firefox 19+ */
  font-size: 0.9em;
}
.searchInput .ant-input:-ms-input-placeholder { /* IE 10+ */
  font-size: 0.9em;
}
.searchInput .ant-input:-moz-placeholder { /* Firefox 18- */
  font-size: 0.9em;
}

.popover-list-filter-trigger.btn svg {
  height: 20px;
  width: 20px;
}

.exclusive-cname {
  svg {
    margin-top: 5px;
  }
}

.create-button-group .ant-btn-default {
  background-color: #00affe;
  color: white;
  border-color: #00a2eb;
}

.batch-edit-button-group .ant-btn {
  background-color: darkorange;
  color: white;
  border-color: #ffae18;
}

.primary-outline {
  color: #40a9ff;
  border-color: #40a9ff;
}
</style>
